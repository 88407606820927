import React from 'react';
import styled from '@emotion/styled';

import Headings from '@components/Headings';
import Image, { ImagePlaceholder } from '@components/Image';
import Section from "@components/Section";

import mediaqueries from '@styles/media';
import { IArticle, IAuthor } from '@types';

interface ArticleHeroProps {
  article: IArticle;
  authors: IAuthor[];
}

const regex = /(<([^>]+)>)/ig; //remove html tag

const PortfolioHero: React.FC<ArticleHeroProps> = ({ article, authors }) => {

  const imageSource = article.thumbnail.full;
  const hasHeroImage =
    imageSource &&
    Object.keys(imageSource).length !== 0 &&
    imageSource.constructor === Object;

  return (
    <Hero>
      <Section narrow>
        <Header dark={article.dark} backgroundColor={article.backgroundColor}>
          <EyeBrowHeading>{article.eyebrowHeadline}</EyeBrowHeading>
          <HeroHeading ><strong>{article.title}</strong></HeroHeading>
          <HeroSubtitle>{article.excerpt}</HeroSubtitle>
        </Header>
      </Section>

      <HeroImage id="PortfolioImage__Hero">
      {hasHeroImage ? (
          <Image src={imageSource} alt={article.title} imgStyle={{ objectFit: 'cover', objectPosition: 'left center' }} />
      ) : (
          <ImagePlaceholder />
      )}
      </HeroImage>
      {/* <OverlayCover dark={article.dark} backgroundColor={article.backgroundColor} /> */}
      
    </Hero>
  );
};

export default PortfolioHero;

const Hero = styled.div`
    position: relative;
    display: grid;
    height: calc(100vh + 48px);
    align-items: center;
    width: 100%;
    overflow: hidden;
    background-color: ${p => p.theme.colors.decorColor01};

    ${mediaqueries.tablet`
      align-items: start;
      padding-top: 200px;
      height: auto;
    `}

    & section {
      z-index: 1;
    }
`;

const Header = styled.header<{
  dark: boolean;
  backgroundColor: string;
}>`
  max-width: 710px;
  color: ${p => p.theme.colors.primary};

  ${mediaqueries.tablet`
    max-width: 100%;
    bottom: 0;
    top: auto;
    text-align: center;
  `}
`;

const HeroHeading = styled(Headings.h1)`
  color: inherit;
  margin-bottom: 16px;
  margin-top: 16px;
  margin-left: -12px;
  letter-spacing: -5px !important;

`;

const EyeBrowHeading = styled(Headings.h5)`
  color: inherit;
  opacity: .7;
`;

const HeroSubtitle = styled(Headings.h5)`
  position: relative;
  color: inherit;
  align-items: center;
  opacity: .7;
  font-weight: ${p => p.theme.fontsWeight.medium};
`;

const HeroImage = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 60%;
    height: 100%;
    overflow: hidden;

    & > div {
      height: 100%;
    }


  ${mediaqueries.tablet`
    position: relative;
    width: 100%;
    top: none;
    left: 0;

  `}
`;

const OverlayCover = styled.div<{
    dark: boolean;
    backgroundColor: string;
  }>`
    position: absolute;
    top: 300px;
    width: 100%;
    height: 60%;
    transition: ${p => p.theme.colorModeTransition};
    z-index: 1;
    color: ${p => (!p.dark ? p.theme.colors.primary : p.theme.colors.textPrimaryDark)};
    background-color: ${p => p.backgroundColor ? p.backgroundColor : 'none'};

    display: none; // hide on desktop

    ${mediaqueries.tablet`
      display: block;
    `}
`;
